import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

import { db } from 'Firebase';
import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export interface Invite {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  flagWedding?: boolean;
  flagChabatHatan: boolean;
  flagHenne: boolean;
  invitBy: string;
  invitationSent: boolean;
}

interface Answer {
  from: string,
  henne: number,
  isFinishedHenne: boolean,
}


interface FirestoreAnswer extends Answer {
  'chabbatHatan': number;
  'wedding': number;
  'henne': number;
}

interface UserContextProps {
  formData: Invite;
  answer: Answer;
  setAnswer: Dispatch<SetStateAction<Answer>>;
  setFormData: Dispatch<SetStateAction<Invite>>;
}

interface FirestoreAnswer {
  chabbatHatan: number;
  wedding: number;
  henne: number;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [uuid, setUuid] = React.useState('');

  const [formData, setFormData] = useState<Invite>({
    id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    flagChabatHatan: false,
    flagHenne: false,
    invitationSent: false,
    invitBy: 'Gabriel',
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newUuid = sessionStorage.getItem("uuid");
      if (newUuid !== uuid && newUuid) {
        setUuid(newUuid);
      }
    }, 500);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [uuid]);


  const [answer, setAnswer] = useState<Answer>({
    from: '',
    henne: -1,
    isFinishedHenne: false,
  });


  const saveAnswers = async () => {
    if (uuid) {
      console.log(uuid);
      const docRef = doc(db, 'answers', uuid);
      const snap = await getDoc(docRef);
      if (!snap.exists()) {
        console.log("Empty =================");
        await setDoc(docRef, answer as any); // Ignore TypeScript error
      } else {
        await updateDoc(docRef, answer as any); // Ignore TypeScript error
      }
      console.log('Answers saved successfully');
    }
  };

  const fetchAnswer = async (): Promise<Answer | undefined> => {
    try {
      if (uuid) {
        const answersCollectionRef = collection(db, 'answers');
        const answersDocRef = doc(answersCollectionRef, uuid);
        const answersDocSnap = await getDoc(answersDocRef);

        if (answersDocSnap.exists()) {
          const answerData = answersDocSnap.data() as FirestoreAnswer;
          const answer: Answer = {
            from: answerData.from,
            henne: answerData.henne,
            isFinishedHenne: answerData.isFinishedHenne
          };
          setAnswer(answer)
          console.log(answer)
          return answer;
        } else {
          setAnswer({
            from: '',
            henne: -1,
            isFinishedHenne: false,
          })
          console.log(`No answers found for ID: ${uuid}`);
          return undefined;
        }
      }
    } catch (error) {
      console.error('Error fetching answers:', error);
      return undefined;
    }
  };

  const fetchUser = async () => {
    if (uuid) {
      const docRef = doc(collection(db, 'invites'), uuid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const newData = docSnap.data() as Invite;
        setFormData({ ...newData, id: uuid });
        setAnswer((answer) => ({ ...answer, from: uuid }));
        console.log(newData);
      } else {
        console.log(`No document found with ID: ${uuid}`);
      }
    }
  };

  useEffect(() => {
    saveAnswers();
  }, [answer]);


  useEffect(() => {
    fetchAnswer()
    fetchUser();
  }, [uuid]);

  const contextValue: UserContextProps = {
    answer,
    setAnswer,
    formData,
    setFormData,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
