/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
import { Header } from 'components/Home/Header';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { useUser } from 'context/user';
import { Button } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
//@ts-ignore
import Poll from 'react-polls';

const yourConfettiConfig = {
  angle: 50,
  spread: 100,
  startVelocity: 50,
  elementCount: 50,
  dragFriction: 0.2,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  colors: ["#e1e1e1", "#ffd700", "#ffffff", "#f1f1f1"],

};

function ThankYouPage() {
  const [confettiActive, setConfettiActive] = useState(false);
  const { formData, setFormData, setAnswer, answer } = useUser();
  const uuid = sessionStorage.getItem("uuid");

  const navigate = useNavigate();

  useEffect(() => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 10000);
  }, []);

  const redirectToWhatsAppGabriel = () => {
    const phoneNumber = '+33672674997';
    const whatsappUrl = `whatsapp://send?phone=${phoneNumber}`;
    window.location.href = whatsappUrl;
  };

  const redirectToWhatsAppAnael = () => {
    const phoneNumber = '+33782991883';
    const whatsappUrl = `whatsapp://send?phone=${phoneNumber}`;
    window.location.href = whatsappUrl;
  };


  function handleRSVPClick(event: any): void {
    navigate(`/rsvp/wedding/${uuid}`);
  }

  const pollStyles2 = {
    questionSeparator: false,
    questionSeparatorWidth: 'question',
    questionBold: false,
    questionColor: '#4F70D6',
    align: 'center',
    theme: 'blue'
  }

  // Object keys may vary on the poll type (see the 'Theme options' table below)
  const customTheme = {
    textColor: 'black',
    mainColor: '#00B87B',
    backgroundColor: 'rgb(255,255,255)',
    alignment: 'center'
  }

  function handleVote(answer: any) {
  }

  // Declaring poll question and answers
  const pollQuestion = 'Musique du slow ? 💃'
  const pollAnswers = [
    {
      option: 'תמר יהלומי & יונתן קלימי - מזל', votes: 8
    },
    { option: 'עדן בן זקן - חיים שלי', votes: 2 },
    { option: 'לכל אחד יש- Shlomi Shabbat', votes: 2 }
  ]

  return (
    <>
      <div className="bg-beige" style={{ height: '100vh' }}>
        <div style={{}}>
          <Header invitationRef={undefined} stepRef={undefined} addressRef={undefined} responseRef={undefined} />
        </div>
        <div className="bg-beige flex justify-center items-center pt-[80px]" style={{}}>
          <div className="text-center">
            {answer.henne > 0 &&
              < p className="text-xl font-bold mb-[10px]">
                Merci {formData.firstName} pour la réponse, hâte de vous voir 😊🥳
              </p>
            }
            {answer.henne == 0 &&
              < p className="text-xl font-bold mb-[10px]">
                Merci {formData.firstName} pour la réponse
              </p>
            }
            <div className="recap-container">
              <div className="bg-white shadow-md rounded my-6 overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b">Evénement</th>
                      <th className="py-2 px-4 border-b">Nombre de personne</th>
                    </tr>
                  </thead>
                  <tbody>
                    {answer.henne !== -1 && (
                      <tr>
                        <td className="py-2 px-4 border-b">Henne</td>
                        <td className="py-2 px-4 border-b">{answer.henne}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='mt-10'>
              Pour ne pas oublier ajouter l’événement à votre calendrier :
            </div>

            <div className='flex flex-row gap-3 justify-center'>
              <AddToCalendarButton
                name="Henne Anaël & Gabriel🪬 "
                description="Royal Plazza"
                startDate="2024-07-04"
                endDate="2024-07-04"
                startTime="19:00"
                endTime="23:59"
                timeZone="Europe/Paris"
                location="223 Rue La Fontaine, 94120 Fontenay-sous-Bois"
                organizer="Gabriel SOUDRY|gabriel_s@hotmail.fr"
                availability="busy"
                options={['Apple', 'Google', 'iCal', 'Outlook.com']}
                hideIconModal
                size="12"
                label="Henne Anaël & Gabriel🪬"
                language="fr"
              ></AddToCalendarButton>

            </div>
            <div className='flex-row flex gap-2 justify-center'>
              <button style={{ color: 'white' }} className="bg-whatsapp-green text-white mt-4 px-6 py-2 rounded hover:bg-whatsapp-darkgreen" onClick={redirectToWhatsAppGabriel}>
                <FontAwesomeIcon icon={faWhatsapp} className="text-xl" /> &nbsp;
                Gabriel
              </button>

              <button style={{ color: 'white' }} className="bg-whatsapp-green text-white mt-4 px-6 py-2 rounded hover:bg-whatsapp-darkgreen" onClick={redirectToWhatsAppAnael}>
                <FontAwesomeIcon icon={faWhatsapp} className="text-xl" /> &nbsp;
                Anaël
              </button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default ThankYouPage;
