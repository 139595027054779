import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  interface Time {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }

  const initialTime: Time = {
    days: 19,
    hours: 10,
    minutes: 24,
    seconds: 49,
  };

  // Convert the initialTime into seconds
  const initialSeconds: number =
    initialTime.days * 24 * 60 * 60 +
    initialTime.hours * 60 * 60 +
    initialTime.minutes * 60 +
    initialTime.seconds;

  // Define the target date (September 30, 2023) at 00:00:00
  const targetDate: Date = new Date('2024-05-10T20:00:44+0000');

  // Get the current timestamp
  const currentDate: Date = new Date();

  // Calculate the time difference in seconds
  const timeDifferenceInSeconds: number = (targetDate.getTime() - currentDate.getTime()) / 1000;

  // Calculate the total time in seconds
  const totalTimeInSeconds: number = initialSeconds + timeDifferenceInSeconds;

  // Calculate days, hours, minutes, and seconds from totalTimeInSeconds
  initialTime.days = Math.floor(totalTimeInSeconds / (24 * 60 * 60));
  initialTime.hours = Math.floor((totalTimeInSeconds % (24 * 60 * 60)) / (60 * 60));
  initialTime.minutes = Math.floor((totalTimeInSeconds % (60 * 60)) / 60);
  initialTime.seconds = Math.floor(totalTimeInSeconds % 60);

  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      // Decrement seconds
      setTime((prevTime) => {
        if (prevTime.seconds > 0) {
          return { ...prevTime, seconds: prevTime.seconds - 1 };
        } else if (prevTime.minutes > 0) {
          return { ...prevTime, minutes: prevTime.minutes - 1, seconds: 59 };
        } else if (prevTime.hours > 0) {
          return { ...prevTime, hours: prevTime.hours - 1, minutes: 59, seconds: 59 };
        } else if (prevTime.days > 0) {
          return { ...prevTime, days: prevTime.days - 1, hours: 23, minutes: 59, seconds: 59 };
        } else {
          clearInterval(timer);
          return prevTime;
        }
      });
    }, 1000);

    // Clean up the interval on unmount
    return () => clearInterval(timer);
  }, []);

  const formatTime = (value: number) => (value < 10 ? `0${value}` : `${value}`);

  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
      <div className="flex bg-beige flex-col p-2 rounded-box">
        <span className="countdown font-mono text text-5xl">
          <span style={{ '--value': formatTime(time.days) } as React.CSSProperties}></span>
        </span>
        days
      </div>
      <div className="flex flex-col p-2 bg-beige rounded-box">
        <span className="countdown font-mono text-5xl">
          <span style={{ '--value': formatTime(time.hours) } as React.CSSProperties}></span>
        </span>
        hours
      </div>
      <div className="flex flex-col p-2 bg-beige rounded-box">
        <span className="countdown font-mono text-5xl">
          <span style={{ '--value': formatTime(time.minutes) } as React.CSSProperties}></span>
        </span>
        min
      </div>
      <div className="flex flex-col p-2 bg-beige rounded-box">
        <span className="countdown font-mono text-5xl">
          <span style={{ '--value': formatTime(time.seconds) } as React.CSSProperties}></span>
        </span>
        sec
      </div>
    </div>
  );
};

export default CountdownTimer;
