import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import 'Routes' instead of 'Switch'
import Henne from 'components/form/Henne';
import ThankYouPage from 'components/form/onComplete';
import { UserProvider } from 'context/user';
import HenneHome from 'Pages/HenneHome';
import PreviewImage from 'PreviewImage';
import { SignIn } from 'Pages/Admin/SignIn';

function App() {
  return (
    <Router>
      <UserProvider>
        <PreviewImage />
        <Routes>
          <Route path="/:uid?" element={<HenneHome />} />
          <Route path="/rsvp/henne/:uid?" element={<Henne />} />
          <Route path="/result/:uid?" element={<ThankYouPage />} />
          <Route path="/adminInvit" element={<SignIn />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
