/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faGoogle, faWaze } from '@fortawesome/free-brands-svg-icons'
import BlackBar from 'components/generic/BlackBar';
// @ts-ignore
import GoogleMapReact from 'google-map-react';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { faDiamondTurnRight, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { Directions } from '@mui/icons-material';

type CastleProps = {
  innerRef: React.MutableRefObject<HTMLDivElement | null>
  imageSrc: string; // Added imageSrc prop
  henne: boolean;
};

const styles = [
  {
    "featureType": "all",
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#878787"
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f9f5ed"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#aee0f4"
      }
    ]
  }
]
function openWazeNavigation() {
  // Replace the coordinates with the actual coordinates of the location
  const latitude = 48.8648649;
  const longitude = 2.4103008;
  const wazeURL = "https://waze.com/ul?ll=48.8588934,2.4835053&navigate=yes"
  window.open(wazeURL, '_blank');
}

function openMapsNavigation() {
  const wazeURL = "https://www.google.com/maps/place/Royal+Plaza/@48.8588894,2.480912,16z/data=!3m1!4b1!4m6!3m5!1s0x47e61299318d6da9:0x40f47b4c42b85f6c!8m2!3d48.8588859!4d2.4834816!16s%2Fg%2F11btwr83hs?entry=ttu"
    ;
  window.open(wazeURL, '_blank');
}

export function Castle({ innerRef, imageSrc, henne }: CastleProps) {
  const defaultProps = {
    center: {
      lat: 48.858890,
      lng: 2.483510
    },
    zoom: 14
  };

  const renderMarkers = (map: any, maps: any) => {
    const marker = new maps.Marker({
      position: { lat: 48.858890, lng: 2.483510 },
      map,
      title: 'Royal Plaza',
      icon: {
        url: 'https://www.svgrepo.com/show/24160/henna-painted-hand.svg',
        scaledSize: new maps.Size(50, 50), // Corrected line
      },
    });
    return marker;
  };

  return (
    <div className='bg-beige'>
      <div className="flex justify-center items-center">
        <img src={imageSrc} alt="chateau" className="max-w-full max-h-full mb-[-50px]" />
      </div>
      <span
        style={{
          paddingTop: '60px',
          fontFamily: 'Great Vibes',
          fontSize: '3em',
          display: 'flex',
          justifyContent: 'center',
          color: 'black',
        }}
      >
        <div>
          L'adresse
        </div>
      </span>
      <div id="test1" className='flex-col justify-center align-middle text-center' ref={innerRef}>
        <span>
          {!henne ? 'Factory 58' : 'Royal Plaza'}
        </span>
        <div>
          {!henne ? '12 Rue Gutenberg, 93000 Bobigny' : '223 Rue La Fontaine, 94120 Fontenay-sous-Bois'}
        </div>

        <div className='flex justify-center flex-row gap-1 mb-[20px] mt-[10px]'>
          <button className="btn btn-active" onClick={() => openWazeNavigation()}>
            <div className="flex">
              <span>
                <FontAwesomeIcon icon={faWaze} className="text-xl" />
                {"    "} En route sur Waze
              </span>
            </div>
          </button>
          <button className="btn btn-active" onClick={() => openMapsNavigation()}>
            <div className="flex">
              <span>
                <FontAwesomeIcon icon={faDiamondTurnRight} className="text-xl" />
                {"    "} En route sur Maps
              </span>
            </div>
          </button>
        </div>

        <div style={{ height: '400px' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyCTm2CvF11MQvhEJHYIZWBi7IGMMnrAkuc" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals={true}
            options={{ styles }}
            // @ts-ignore
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          >

          </GoogleMapReact>
        </div>

      </div>
      <BlackBar />
    </div >
  );
}
