import { useUser } from 'context/user';
import React from 'react';
import { useNavigate } from "react-router-dom";

type RSVPProps = {
  innerRef: React.MutableRefObject<HTMLDivElement | null>
};

function RSVP({ innerRef }: RSVPProps) {
  const navigate = useNavigate();
  const { formData, answer } = useUser()

  const handleRSVPClick = () => {
    const uuid = sessionStorage.getItem("uuid");

    if (!answer.isFinishedHenne) {
      navigate(`/rsvp/henne/${uuid}`);
    }
    else {
      navigate(`/result/${uuid}`);
    }
  };

  return (
    <div>
      <div
        ref={innerRef}
        id='response'
        style={{
          fontFamily: 'Great Vibes',
          fontSize: '2.5em',
          display: 'flex',
          justifyContent: 'center',
          color: 'black',
          paddingBottom: '20px'
        }}
      >
        Confirmez votre présence
      </div>
      <div className="max-w-sm mx-auto mt-4 px-6 text-center" style={{ fontFamily: "'AddingtonCF-Thin', Georgia, serif" }}>
        Nous vous demandons de bien vouloir nous faire parvenir votre réponse au plus vite, en cliquant ci-dessous ou bien par WhatsApp à Gabriel ou Anaël.
      </div>


      <div className='flex justify-center'>
        <button
          className="mt-8 mb-10 text-white w-[90%] py-4 rounded-full transition-transform transform hover:scale-105"
          onClick={handleRSVPClick}
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', fontSize: '19px', fontWeight: 'bold', color: 'white', boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}
        >
          RSVP ❤️
        </button>
      </div>
    </div>
  );
}

export default RSVP; // Use withRouter to access the history object
