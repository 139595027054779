/* eslint-disable import/no-unused-modules */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import BlackBar from "../generic/BlackBar";
import React from 'react';
import image2 from "../../assets/invitation henne (3).png"

type InvitationProps = {
  innerRef: React.MutableRefObject<HTMLDivElement | null>;
};

export function Invitation({ innerRef }: InvitationProps) {
  const goldColor = '#ba9c18'

  const curvedTextStyles = {
    fontFamily: 'addingtoncf-thin',
    fontSize: '1.3em', // Adjust the font size as needed
  };
  const bigGoldTextStyles = {
    fontFamily: 'YourGoldFont', // Replace 'YourGoldFont' with your chosen gold handwritten style font
    fontSize: '2em', // Adjust the font size for the big text
    color: goldColor,
    display: 'flex',
  };

  return (
    <>
      <div className="bg-beige h-[550px] flex justify-center" ref={innerRef}>
        <img src={image2}></img >
      </div>
      <BlackBar />
    </>
  );
}
